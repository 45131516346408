import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Box } from "@chakra-ui/react"

export function HeroImage({ src, removeNegativeMargin = false }) {
  return (
    <Box
      height="500px"
      position="relative"
      mb={{ base: removeNegativeMargin ? 0 : -24, lg: -24 }}
      bg="blue.100"
    >
      <GatsbyImage
        image={src}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          zIndex: 1,
        }}
      />
    </Box>
  )
}
