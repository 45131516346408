import React from "react"
import { Helmet } from "react-helmet"
import { graphql, Link } from "gatsby"
import { Box, Flex, Text } from "@chakra-ui/react"
import { Container } from "../components/Container"
import { HeroImage } from "../components/HeroImage"
import { Heading } from "../components/Heading"
import { Layout } from "../layouts/default"

// Sanity helpers
import PortableText from "../components/PortableText"
import { getFluidProps } from "../lib/sanity"
import { FormulationChallengeBlock } from "../components/Blocks/FormulationChallengeBlock"
import { ArrowBackIcon } from "@chakra-ui/icons"
import { DynamicLink } from "../components/DynamicLink"

import langStrings from "../locales/lang.json"

function EventTemplate({ data, pageContext }) {
  const { locale } = pageContext

  const {
    title,
    involvement,
    featureImage,
    _rawAbstract,
    eventStart,
    eventStartFr,
    eventEnd,
    eventEndFr,
    seo,
  } = data.sanityEvent

  // Build up fluid props for the gatsby image
  const fluidProps = featureImage?._rawAsset
    ? {
        id: featureImage._rawAsset._ref,
        maxWidth: 1800,
      }
    : {}

  return (
    <Layout>
      <Helmet>
        <title>{title[locale]}</title>
        <meta name="keywords" content={seo ? seo.keywords : ""} />
        <meta name="description" content={seo ? seo.description : ""} />
      </Helmet>

      {featureImage && <HeroImage src={getFluidProps(fluidProps)} />}

      <Container px={0}>
        {title && (
          <Flex
            flexDir={{ base: "column", lg: "row" }}
            justifyContent={{ lg: "space-between" }}
          >
            <Box
              bg="white"
              width={{ md: "50%" }}
              p={{ base: 4, md: 12 }}
              pb={0}
              pos="relative"
              zIndex={10}
            >
              <DynamicLink
                to="/making-products-better/expert-events"
                style={{ textDecoration: "none" }}
              >
                <Text mb={4} fontSize={14} color="brand.400" fontWeight="bold">
                  <ArrowBackIcon mr={2} />
                  {langStrings.events.back[locale]}
                </Text>
              </DynamicLink>

              <Heading fontSize="40px" mb={2}>
                {title[locale]}
              </Heading>

              {involvement && (
                <Text fontWeight="bold" mt={4} mb={{ sm: 4 }} fontSize={18}>
                  {involvement === "Attending"
                    ? "Micropore will be attending this event"
                    : "Micropore will be presenting at this event"}
                </Text>
              )}
              <Text mt={5} textTransform="capitalize">
                {locale === "en" ? eventStart : eventStartFr}
                {eventEnd && ` - ${locale === "en" ? eventEnd : eventEndFr}`}
              </Text>
            </Box>
          </Flex>
        )}

        <Container bg="white">
          {_rawAbstract && _rawAbstract[locale] && (
            <Box mb={16}>
              <PortableText blocks={_rawAbstract[locale]} />
            </Box>
          )}
        </Container>

        <Container pt={4} borderTopWidth={2} borderTopColor="gray.300">
          <FormulationChallengeBlock locale={locale} />
        </Container>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query EventQuery($id: String!) {
    sanityEvent(id: { eq: $id }) {
      id
      title {
        en
        fr
        es
        it
        de
        nl
        ko
        ja
      }
      involvement
      eventStart(formatString: "dddd Do MMMM YYYY")
      eventEnd(formatString: "dddd Do MMMM YYYY")
      eventStartFr: eventStart(formatString: "dddd Do MMMM YYYY", locale: "fr")
      eventEndFr: eventEnd(formatString: "dddd Do MMMM YYYY", locale: "fr")

      _rawAbstract
      slug {
        current
      }
      featureImage {
        _rawAsset
      }
      seo {
        keywords
        description
      }
    }
  }
`

export default EventTemplate
